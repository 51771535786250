.searchBox {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
}

.logosBox {
    display: grid;
    grid-template-columns: 40% 60%;
    color: $white;
    align-items: center;

    .logoImg {
        height: 22vh;
        margin: 5vh 2vh 5vh 14vh;
    }

    p {
        font-size: 4vh;
        line-height: 20pt;
    }
    span { font-size: 2vh; line-height: 2pt; }
}


.form{
    z-index: 999999999999999999999999999999999999;
    display: flex;
    flex-direction: column;
    color: $white;
    justify-content: space-between;
    margin: 2vh;
    
    div {
        display: grid;
        grid-template-columns: 12% 80%;
        align-items: center;
        text-align: center;
       

        p {
            border: 2px solid $globalColor;
            border-radius: 5px;
            padding: 1vh;
            margin-right: 2vh;
            font-size: 2vh;
        }

        input {
            background-color: $background;
            border-bottom: 1px solid $white;
        }
    }

    .reportsBox {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        margin: auto 1vh;
        text-align: left;
        height: 30%;
        
        .reports {
            border: 1px solid transparent;
            text-align: left;
            margin-left: 4vh;
            width: 50vh;
            margin-top: -0.5vh;
        }

    }

    .title{
        text-align: center;
    }
    input, select, textarea {
        outline: none;
        border: none;
        margin: .8rem .3rem;
        padding: .5rem 1rem;
        font-size: 1.05rem;
        resize: none;
        transition: all .3s ease-in-out;
        color: $white;
        
        &:hover{ border-bottom: 1px dashed $white; }
        &:focus{ border-bottom: 1px solid $globalColor; }
    }
    
    
    button{
        cursor: pointer;
        margin: 1rem auto;
        padding: .5rem 1rem;
        border: none;
        border-radius: .3rem;
        grid-column: 1 / 3;
        align-items: center;
        width: 38vh;
        height: 6vh;
        font-weight: bold;
        font-size: 3vh;
        background-color: $globalColor;
        transition: all .3s ease-in-out;

        &:hover{
            color: $globalColor;
            border: 4px solid $globalColor;
            background-color: $background;
        }
    }
}
.form.row{
    z-index: 999999999999999999999999999999999999;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
}

/* SQUARED ONE */
.squaredOne {
    width: 28px;
    height: 28px;
    background: $background;
    border:4px solid $globalColor;
    border-radius: 5px;

    // filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#fcfff4', endColorstr='#b3bead',GradientType=0 );
    position: relative;
}

.squaredOne label {
    cursor: pointer;
    position: absolute;
    width: 20px;
    height: 20px;
    left: 4px;
    top: 4px;
}

.squaredOne label:after {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
    opacity: 0;
    content: '';
    position: absolute;
    width: 26px;
    height: 26px;
    background: #00bf00;
    top: -3px;
    left: -3px;

    -webkit-box-shadow: inset 0px 1px 1px white, 0px 1px 3px rgba(0,0,0,0.5);
    -moz-box-shadow: inset 0px 1px 1px white, 0px 1px 3px rgba(0,0,0,0.5);
    box-shadow: inset 0px 1px 1px white, 0px 1px 3px rgba(0,0,0,0.5);
}

.squaredOne label:hover::after {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=30)";
    filter: alpha(opacity=30);
    opacity: 0.3;
}

.squaredOne input[type=checkbox]:checked + label:after {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
    filter: alpha(opacity=100);
    opacity: 1;
}
/* SQUARED ONE */

.squaredTwo {
    @extend .squaredOne;
}

.squaredThree {
    @extend .squaredOne;
}