.sendBox {
    margin: 2vh auto;
    display: flex;
    justify-content: center;

    .sendImg { width: 80%; }
    p { color: $white; }
}

.buttonExit {
    cursor: pointer;
    margin: 1rem auto;
    padding: .5rem 1rem;
    border: none;
    border-radius: .3rem;
    align-items: center;
    width: 38vh;
    height: 6vh;
    font-weight: bold;
    font-size: 3vh;
    background-color: $globalColor;
    transition: all .3s ease-in-out;

    &:hover{
        color: $globalColor;
        border: 4px solid $globalColor;
        background-color: $background;
    }
}

.contextBox {
   display: grid;
    grid-template-columns: repeat(3, 1fr);
    text-align: center;
    width: 78%;
    margin: auto;

    .reportsBox {
        color: $white;

        .title { text-transform: uppercase; font-weight: bold; }
    }

    .emailsBox {
        color: $white;

        .title { text-transform: uppercase; font-weight: bold; }
    }

    .receptorBox {
        color: $white;

        .title { text-transform: uppercase; font-weight: bold; }
    }
}


.buttonsBox {

    .buttonReturn {
        cursor: pointer;
        margin: 1rem auto;
        padding: .5rem 1rem;
        width: 38vh;
        height: 6vh;
        font-weight: bold;
        border-radius: 7px;
        font-size: 3vh;
        margin-left: 30%;
        margin-right: 2vh;
        color: $white;
        background-color: $background;
        border: 2px solid $white;
        transition: all .3s ease-in-out;

        &:hover{
            color: $background;
            background-color: $white;
        }
    }
}